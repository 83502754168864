<template>
	<v-container fluid style="max-width: 1500px">
		<v-fade-transition hide-on-leave>
			<template v-if="loadingOffer">
				<v-skeleton-loader key="skeleton" type="list-item-avatar" min-width="1500" height="120" class="rounded-xl" />
			</template>
			<template v-else>
				<div class="d-flex justify-space-between flex-wrap">
					<v-row key="offerFirstRow">
						<v-col cols="12" md="2" sm="3" xs="5">
							<OfferLogo :loading-parent="loadingOffer" />
						</v-col>
						<v-col cols="12" md="10" sm="9" xs="7">
							<OfferInfo :loading-parent="loadingOffer" />
						</v-col>
					</v-row>
				</div>
			</template>
		</v-fade-transition>

		<v-divider class="my-2" />

		<v-chip-group v-model="chipSelected" column color="sidebarBackground" active-class="sidebarBackground" class="my-3">
			<v-chip value="OfferAbout" :disabled="chipSelected == 'OfferAbout'">
				<v-btn text :to="{ name: 'OfferAbout', params: { id: offer ? offer.id : 0 } }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-information</v-icon>
					{{ $t('offers.aboutTheOffer') }}
				</v-btn>
			</v-chip>
			<v-chip value="OfferBenefits" :disabled="chipSelected == 'OfferBenefits'">
				<v-btn text :to="{ name: 'OfferBenefits', params: { id: offer ? offer.id : 0 } }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-currency-eur</v-icon>
					{{ $t('offers.benefits') }}
				</v-btn>
			</v-chip>
			<v-chip value="OfferNeeds" :disabled="chipSelected == 'OfferNeeds'">
				<v-btn text :to="{ name: 'OfferNeeds', params: { id: offer ? offer.id : 0 } }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-account-question</v-icon>
					{{ $t('offers.requisites') }}
				</v-btn>
			</v-chip>
			<v-chip value="OfferActivity" :disabled="chipSelected == 'OfferActivity'">
				<v-btn text :to="{ name: 'OfferActivity', params: { id: offer ? offer.id : 0 } }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-rss</v-icon>
					{{ $t('offers.activity') }}
				</v-btn>
			</v-chip>
			<v-chip value="OfferApplications" :disabled="chipSelected == 'OfferApplications'" v-if="!offer.category.justExternal">
				<v-btn text :to="{ name: 'OfferApplications', params: { id: offer ? offer.id : 0 } }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-account-group</v-icon>
					{{ $t('applications.applications') }}
				</v-btn>
			</v-chip>
			<v-chip value="OfferTalentPool" :disabled="chipSelected == 'OfferTalentPool'" v-if="!offer.category.justExternal && company.limits.pool">
				<v-btn text :to="{ name: 'OfferTalentPool', params: { id: offer ? offer.id : 0 } }" block rounded plain class="px-0">
					<v-icon class="mr-3">mdi-account-search</v-icon>
					Talent Pool
				</v-btn>
			</v-chip>
		</v-chip-group>

		<v-divider class="my-2" />

		<router-view />
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.title ? `${this.$t('offers.offer')} - ${this.title}` : this.$t('offers.offer')
		}
	},
	data() {
		return {
			title: '',
			chipSelected: 'OfferAbout'
		}
	},
	components: {
		OfferLogo: () => import('@/components/offers/OfferLogo.vue'),
		OfferInfo: () => import('@/components/offers/OfferInfo.vue')
	},
	watch: {
		'$route.params.id'(id) {
			this.setLoadingOffer(true)
			this.fetchOffer(id)
				.then(({ success }) => {
					if (success) this.title = this.offer.title
				})
				.then(() => {
					this.setLoadingOffer(false)
				})
		}
	},
	computed: {
		...mapGetters({
			offer: 'offers/offer',
			company: 'companies/company',
			loadingOffer: 'offers/loading'
		})
	},
	created() {
		this.chipSelected = this.$route.name
		this.setLoadingOffer(true)
		this.fetchOffer(this.$route.params.id)
			.then(({ success }) => {
				if (success) this.title = this.offer.title
			})
			.then(() => {
				this.setLoadingOffer(false)
			})
	},
	methods: {
		...mapActions('offers', ['setLoadingOffer', 'fetchOffer'])
	}
}
</script>

<style scoped>
.v-chip--disabled {
	opacity: 1;
}
</style>
